import request from './request'

//权限分类列表
export const route_model_index_request = p => {
    return request({
        method:'GET',
        url:'route_model/index',
        params: p
    })
}

//添加权限分类
export const route_model_add_request = d => {
    return request({
        method:'POST',
        url:'route_model/add',
        data: d
    })
}

//删除权限分类
export const route_model_del_request = id => {
    return request({
        method:'DELETE',
        url:'route_model/del',
        data: {
            id: id
        }
    })
}

//修改权限分类
export const route_model_edit_request = data => {
    return request({
        method:'PUT',
        url:'route_model/edit',
        data
    })
}

//获取权限分类详情
export const route_model_details_request = id => {
    return request({
        method:'GET',
        url:'route_model/details',
        params: {
            id
        }
    })
}

//权限模块、权限分组两级联动数据
export const route_model_team_request = () => {
    return request({
        method:'GET',
        url:'route_model/route_model_team'
    })
}